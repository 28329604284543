@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;500;600&family=Quicksand:wght@600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


.app {
    @apply bg-fam-bg-dark text-white min-h-screen font-light flex flex-col;
}

.default-bg {
    @apply fixed h-screen w-screen z-0;
    opacity: 0.10;
    filter: blur(8px);
    background-image: url('../public/pebbles.jpg');
    background-size: cover;
}

.grandma-bg {
    @apply fixed h-screen w-screen z-0;
    /*opacity: 0.10;*/
    filter: blur(2px);
    background-image: url('../public/flowers.jpg');
    background-size: cover;
}

h1, h2 {
    @apply font-display;
}

.tile {
    @apply rounded-lg bg-fam-bg-dark p-8 drop-shadow-lg my-4 border border-fam-teal border-solid space-y-4 flex flex-col;
}

.slots {
    @apply rounded-lg bg-fam-bg-dark drop-shadow-lg my-4 border border-fam-teal border-solid flex flex-col;
}

.slots *:last-child {
    @apply border-b-0;
}

.kid {
    @apply bg-[#7350BC];
}

.grandma {
    @apply bg-rose-100 text-neutral-600 border-rose-300;
}

.grandma.slots > div {
    @apply border-rose-300;
}